import { ref } from 'vue'
import { defineStore } from 'pinia'
import { minuteToMillis } from '@/shared/utils/time'
import { useToastStore } from '@/shared/stores/toast'
import { useMaintenanceApi } from '@/api/services/maintenance'
import { enUS } from 'date-fns/locale'
import { formatDistanceToNow } from 'date-fns'
import { DEFAULT_MAINTENANCE_INTERVAL, WARNING } from '@/constants'
import type { MaintenanceSchedule } from '@/api/models/types'

export const useMaintenanceStore = defineStore('maintenance', () => {
  const maintenanceApi = useMaintenanceApi()
  const toastStore = useToastStore()
  const isReminder = ref<boolean>(false)
  const activeFrom = ref<string>('')
  const timeRemaining = ref<string>('')
  const toastTimerId = ref<NodeJS.Timeout>()
  const timerId = ref<NodeJS.Timeout>()
  const reminderInterval = ref<number>()

  const getTime = (minute?: number): number => (minute ? minuteToMillis(minute) : DEFAULT_MAINTENANCE_INTERVAL)

  const getRemainingTime = (message: string, from?: string): string =>
    `${message} ${from ? formatDistanceToNow(from, { locale: enUS }) : ''}`

  const setReminderInterval = (interval?: number): void => {
    clearInterval(toastTimerId.value)
    toastTimerId.value = setInterval(() => toastStore.showToast(WARNING, timeRemaining.value), getTime(interval))
  }

  const setNextMaintenance = (time?: number): void => {
    clearTimeout(timerId.value)
    timerId.value = setTimeout(() => getMaintenance(), getTime(time))
  }

  const setReminder = (interval?: number) => {
    if (reminderInterval.value !== interval) {
      setReminderInterval(interval)
    }
    reminderInterval.value = interval
  }

  const setNotification = (schedule: MaintenanceSchedule) => {
    isReminder.value = schedule.isReminder
    activeFrom.value = schedule.activeFrom
    timeRemaining.value = getRemainingTime(schedule.reminderMessage, schedule.activeFrom)
    setReminder(schedule.reminderInterval)
    setNextMaintenance(schedule.activeInterval)
  }

  const getMaintenance = async (): Promise<void> => {
    const { data, error } = await maintenanceApi.getMaintenance()
    if (!error && data) {
      if (data.isReminder) {
        setNotification(data)
      } else if (data.isActive) {
        clearTimeout(timerId.value)
        clearInterval(toastTimerId.value)
        window.location.assign(`${import.meta.env.VITE_LOBBY_BASE_URL}/maintenance`)
      } else {
        setNextMaintenance(data.interval)
      }
    }
  }

  return {
    isReminder,
    timeRemaining,
    getMaintenance,
    setReminderInterval,
  }
})
