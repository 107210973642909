import { useApi } from '..'
import { useUserStore } from '@/modules/user/stores/user'
import type {
  ClientErrorData,
  MBoxDetailsRequest,
  TrackingUrlResponse,
  OrderLinesResponse,
  OrderLineDetailsRequest,
  OrderLineResponse,
  MboxDetailsSectionResponse,
} from '../models/types'

export const useSideMenuApi = () => {
  const api = useApi()
  const user = useUserStore()
  const mainUrl = import.meta.env.VITE_MAIN_BASE_URL

  const fetchSideMenuInformationGeneral = async (
    payload: MBoxDetailsRequest,
  ): Promise<{ data: MboxDetailsSectionResponse | null; error: ClientErrorData | null }> => {
    return await api.request.post(mainUrl, `view/${user.viewId}/side-menu/information-general`, JSON.stringify(payload))
  }

  const fetchSideMenuInformationShipment = async (
    payload: MBoxDetailsRequest,
  ): Promise<{ data: MboxDetailsSectionResponse | null; error: ClientErrorData | null }> => {
    return await api.request.post(
      mainUrl,
      `view/${user.viewId}/side-menu/information-shipment`,
      JSON.stringify(payload),
    )
  }

  const fetchSideMenuInformationRecipient = async (
    payload: MBoxDetailsRequest,
  ): Promise<{ data: MboxDetailsSectionResponse | null; error: ClientErrorData | null }> => {
    return await api.request.post(
      mainUrl,
      `view/${user.viewId}/side-menu/information-recipient`,
      JSON.stringify(payload),
    )
  }

  const fetchSideMenuProducts = async (
    payload: MBoxDetailsRequest,
  ): Promise<{ data: OrderLinesResponse | null; error: ClientErrorData | null }> =>
    await api.request.post(mainUrl, `view/${user.viewId}/side-menu/order-lines`, JSON.stringify(payload))

  const fetchSideMenuShipments = async (
    payload: MBoxDetailsRequest,
  ): Promise<{ data: MboxDetailsSectionResponse | null; error: ClientErrorData | null }> =>
    await api.request.post(mainUrl, `view/${user.viewId}/side-menu/shipments`, JSON.stringify(payload))

  const fetchTrackingUrl = async (
    objectId: string,
  ): Promise<{ data: TrackingUrlResponse | null; error: ClientErrorData | null }> =>
    await api.request.get<TrackingUrlResponse>(
      mainUrl,
      `view/${user.viewId}/side-menu/trackingurl?objectId=${objectId}`,
    )

  const fetchSideOrderLineDetails = async (
    payload: OrderLineDetailsRequest,
  ): Promise<{ data: OrderLineResponse | null; error: ClientErrorData | null }> =>
    await api.request.post(mainUrl, `view/${user.viewId}/side-menu/order-line-details`, JSON.stringify(payload))

  return {
    fetchSideMenuInformationGeneral,
    fetchSideMenuInformationShipment,
    fetchSideMenuInformationRecipient,
    fetchSideMenuProducts,
    fetchSideMenuShipments,
    fetchTrackingUrl,
    fetchSideOrderLineDetails,
  }
}
