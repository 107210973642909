import { useApi } from '..'
import { useUserStore } from '@/modules/user/stores/user'
import type { ClientErrorData, SearchItemResult } from '../models/types'

export const useSearchApi = () => {
  const api = useApi()
  const user = useUserStore()
  const mainUrl = import.meta.env.VITE_MAIN_BASE_URL

  const getSearchResult = async (
    query: string,
  ): Promise<{ data: SearchItemResult | null; error: ClientErrorData | null }> =>
    await api.request.get<SearchItemResult>(mainUrl, `view/${user.viewId}/search?query=${query}`)

  return { getSearchResult }
}
