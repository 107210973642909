import { useApi } from '..'
import type { ClientErrorData, TokenResponse } from '../models/types'

export const useSessionApi = () => {
  const api = useApi()
  const lobbyUrl = import.meta.env.VITE_LOBBY_BASE_URL

  const getResume = async (): Promise<{ data: TokenResponse | null; error: ClientErrorData | null }> =>
    await api.request.get<TokenResponse>(lobbyUrl, 'user/resume')

  const getAccessToken = async (): Promise<{ data: TokenResponse | null; error: ClientErrorData | null }> =>
    await api.request.get<TokenResponse>(lobbyUrl, 'user/refresh-token')

  return { getResume, getAccessToken }
}
