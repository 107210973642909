import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useSideMenuStore = defineStore('sideMenu', () => {
  const notificationModalImageUrl = ref<string>('')
  const notificationModalImageError = ref<string>('')
  const isOpen = ref<boolean>(false)

  const open = () => {
    isOpen.value = true
  }

  const close = () => {
    isOpen.value = false
  }

  return { isOpen, open, close, notificationModalImageUrl, notificationModalImageError }
})
