<script setup lang="ts">
import { mdiCircleMedium } from '@mdi/js'
import SvgIcon from '@/shared/components/SvgIcon.vue'

interface Props {
  title: string
  text?: string
  icon: string
}

const model = defineModel<boolean>({ required: true })
defineProps<Props>()
</script>

<template>
  <div class="flex items-center truncate cursor-pointer p-1.5" @mouseover="model = false">
    <SvgIcon :path="icon" class="fill-gray-700 h-10 w-10" />
    <div class="ms-1.5 w-full">
      <div class="flex justify-between font-medium text-sm">
        {{ title }}
        <SvgIcon v-if="model" :path="mdiCircleMedium" class="fill-error h-5 w-5" />
      </div>
      <div class="text-xs text-gray-700 truncate">{{ text }}</div>
    </div>
  </div>
  <slot></slot>
</template>
