<script setup lang="ts">
import { INFO, ERROR, SUCCESS, WARNING } from '@/constants'
import { mdiClose, mdiAlertOutline, mdiCheckCircleOutline, mdiAlertCircleOutline, mdiInformationOutline } from '@mdi/js'
import { useToastStore } from '../stores/toast'
import SvgIcon from './SvgIcon.vue'
import UnstyledButton from './UnstyledButton.vue'

const toastStore = useToastStore()
const toast = toastStore.getToast()
</script>

<template>
  <div
    class="z-50 fixed bottom-14 right-4 transform transition-all ease-in-out duration-300"
    :class="toast.text ? 'translate-x-0 opacity-100' : 'translate-x-72 opacity-0'"
  >
    <div class="flex items-center bg-gradient-to-t from-gray-900/90 to-gray-900/80 ps-3 shadow-lg rounded-lg">
      <div class="inline-flex items-center justify-center me-2">
        <SvgIcon v-if="toast.type === INFO" :path="mdiInformationOutline" class="fill-info w-10 h-10" />
        <SvgIcon v-if="toast.type === SUCCESS" :path="mdiCheckCircleOutline" class="fill-success w-10 h-10" />
        <SvgIcon v-if="toast.type === WARNING" :path="mdiAlertOutline" class="fill-warning w-10 h-10" />
        <SvgIcon v-if="toast.type === ERROR" :path="mdiAlertCircleOutline" class="fill-error w-10 h-10" />
      </div>
      <div class="me-3">
        <div class="font-medium">
          <div v-if="toast.type === INFO" class="text-info">
            {{ $t('glossary.information') }}
          </div>
          <div v-if="toast.type === SUCCESS" class="text-success">
            {{ $t('glossary.success') }}
          </div>
          <div v-if="toast.type === WARNING" class="text-warning">
            {{ $t('glossary.warning') }}
          </div>
          <div v-if="toast.type === ERROR" class="text-error">
            {{ $t('glossary.error') }}
          </div>
        </div>
        <div class="font-light text-sm text-white">
          {{ toast.text }}
        </div>
      </div>
      <div class="flex items-center">
        <UnstyledButton
          :title="$t('common.close')"
          class="group flex items-center justify-center hover:bg-gray-700/30 hover:shadow-inner border-l border-gray-700 px-1 py-2 rounded-e-xl"
          @click="toastStore.clearToast()"
        >
          <SvgIcon :path="mdiClose" class="fill-white w-6 h-12 group-hover:fill-white" />
          <span class="sr-only">{{ $t('common.close') }}</span>
        </UnstyledButton>
      </div>
    </div>
  </div>
</template>
