<script setup lang="ts">
import UnstyledButton from './UnstyledButton.vue'
import SvgIcon from './SvgIcon.vue'

interface Props {
  title: string
  icon: string
  badge?: boolean
}

defineProps<Props>()
const model = defineModel<boolean>({ required: true })
</script>

<template>
  <UnstyledButton
    v-if="icon"
    :title="title"
    class="flex items-center rounded-full p-1"
    :class="model ? 'bg-white/20' : 'hover:bg-white/10'"
    @click="model = !model"
  >
    <SvgIcon :path="icon" class="fill-primary-light h-6 w-6" />
    <span
      v-if="badge"
      class="absolute top-1 right-1 bg-error border-2 border-primary-dark fill-error h-3 w-3 rounded-full"
    ></span>
  </UnstyledButton>
</template>
