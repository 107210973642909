import { createRouter, createWebHistory, type RouteMeta } from 'vue-router'
import { authGuard } from './auth-guard'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('../views/DashboardView.vue'),
    },
    {
      path: '/motion-view',
      alias: ['/outbound'],
      name: 'motionView',
      component: () => import('../views/MotionView.vue'),
    },
    {
      path: '/product-call-off',
      name: 'productCallOff',
      component: () => import('../views/ProductCallOffView.vue'),
    },
    {
      path: '/user-management',
      name: 'user-management',
      component: () => import('../views/UserManagementView.vue'),
      beforeEnter: authGuard,
      meta: { HideHeaderSearch: true } as RouteMeta,
    },
    { path: '/:pathMatch(.*)*', redirect: '/' },
  ],
})

export default router
