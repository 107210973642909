<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useSideMenuStore } from '@/modules/side-menu/stores/side-menu'
import { useUserStore } from '@/modules/user/stores/user'
import { useClientStore } from '@/modules/client/store/client'
import { REFRESH_HOME, REFRESH_MOTION } from '@/constants'
import { useSearchStore } from '@/modules/search/stores/search'
import { useFilterStore } from '@/modules/filter/stores/filter'
import { useThemeStore } from '@/shared/stores/theme'
import type { RouteScopeMeta } from '@/shared/interfaces/routeMeta'
import VersionUpdateMenu from '@/modules/version/components/VersionUpdateMenu.vue'
import ClientSelect from '@/modules/client/components/ClientSelect.vue'
import SearchBar from '@/modules/search/components/SearchBar.vue'
import UserMenu from '@/modules/user/components/UserMenu.vue'
import EventBus from '@/event-bus'
import NotificationMenu from './notification/NotificationMenu.vue'

const userStore = useUserStore()
const clientStore = useClientStore()
const searchStore = useSearchStore()
const themeStore = useThemeStore()
const sideMenu = useSideMenuStore()
const filterStore = useFilterStore()
const router = useRouter()
const { viewId, views } = storeToRefs(userStore)
const { clientKey } = storeToRefs(clientStore)
const { isSearchActive, searchResult } = storeToRefs(searchStore)
const { groupId } = storeToRefs(filterStore)
const showClientSelector = ref<boolean>(true)
const showSearch = ref<boolean>(true)

const refreshMotion = (): void => {
  EventBus.emit(REFRESH_MOTION)
}

const refreshHome = (): void => {
  EventBus.emit(REFRESH_HOME)
}

const updateClient = async (): Promise<void> => {
  if (clientKey.value) {
    isSearchActive.value = false
    groupId.value = undefined
    searchResult.value = undefined
    sideMenu.close()
    await userStore.refreshUserSettings(clientKey.value)
    themeStore.setDefaultTheme(viewId.value, views.value)
    filterStore.clearAll()
    refreshMotion()
    refreshHome()
  }
}

router.afterEach(() => {
  const meta = router.currentRoute.value.meta as unknown as RouteScopeMeta
  if (meta) {
    showClientSelector.value = !meta.HideHeaderClientSelector
    showSearch.value = !meta.HideHeaderSearch
  } else {
    showClientSelector.value = true
    showSearch.value = true
  }
})
</script>

<template>
  <nav class="z-20 fixed top-0 bg-primary-dark shadow-md w-full py-1">
    <div class="flex items-center">
      <div class="flex flex-1 items-stretch justify-start">
        <div class="inline-flex items-center">
          <div class="ms-3 pe-2">
            <img :alt="$t('glossary.logo')" src="@/assets/images/logo_invert.png" class="w-6 h-6" />
          </div>
        </div>
        <div class="flex items-center text-2xl whitespace-nowrap tracking-wider">
          <span class="text-white">{{ $t('glossary.our') }}</span>
          <span class="text-black">{{ $t('glossary.box', 2) }}</span>
        </div>
        <div class="flex items-center justify-center ms-3">
          <ClientSelect
            v-model="clientKey"
            :clients="userStore.clients"
            class="min-w-80"
            @update="updateClient()"
            v-if="showClientSelector"
          />
        </div>
      </div>
      <div class="flex items-center gap-2 me-3">
        <SearchBar v-if="showSearch" />
        <NotificationMenu />
        <VersionUpdateMenu />
        <UserMenu />
      </div>
    </div>
  </nav>
</template>
