import { useSessionStore } from '@/shared/stores/session'
import { useClientStore } from '@/modules/client/store/client'
import { SystemErrorCodes, ProfileActionHint, PublicSupportCode } from '@/api/models/definitions'
import type { ClientErrorData, ResponseBase } from '@/api/models/types'

export const useApi = () => {
  const apiVersion = import.meta.env.VITE_API_VERSION
  const loginPath = import.meta.env.VITE_AUTH_LOGIN_PATH
  const session = useSessionStore()
  const clientStore = useClientStore()

  const getConfig = (method: string, body?: BodyInit): RequestInit => {
    const request: RequestInit = {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${session.accessToken}`,
        'Addimotion-Client': clientStore.clientKey,
      },
      mode: 'cors',
      credentials: 'include',
    }
    if (body) {
      request.body = body
    }
    return request
  }

  const request = {
    get: <TResponse>(baseUrl: string, resource: string) => query<TResponse>(baseUrl, resource, getConfig('GET')),
    put: <TBody extends BodyInit, TResponse>(baseUrl: string, resource: string, body?: TBody) =>
      query<TResponse>(baseUrl, resource, getConfig('PUT', body)),
    post: <TBody extends BodyInit, TResponse>(baseUrl: string, resource: string, body?: TBody) =>
      query<TResponse>(baseUrl, resource, getConfig('POST', body)),
    patch: <TBody extends BodyInit, TResponse>(baseUrl: string, resource: string, body?: TBody) =>
      query<TResponse>(baseUrl, resource, getConfig('PATCH', body)),
    delete: <TBody extends BodyInit, TResponse>(baseUrl: string, resource: string, body?: TBody) =>
      query<TResponse>(baseUrl, resource, getConfig('DELETE', body)),
  }

  const query = async <TResponse>(
    baseUrl: string,
    resource: string,
    config: RequestInit,
  ): Promise<{ data: TResponse | null; error: ClientErrorData | null }> => {
    const { data, error } = await tryFetch(`${baseUrl}${apiVersion}/${resource}`, config)
    if (error) {
      return { data: null, error: error }
    }
    return { data: data as TResponse, error: error }
  }

  const parseError = async (bodyOrResponse: any) => {
    const errorData: ClientErrorData = bodyOrResponse.errorData
    const error = {
      code: errorData.code,
      errClass: errorData.errClass || 'unknown',
      message: errorData.message || 'Unknown error',
      actionHint: errorData.actionHint,
    } as ClientErrorData | null
    return { data: bodyOrResponse, error: error }
  }

  const tryFetch = async <TResponse>(
    url: string,
    config: RequestInit,
  ): Promise<{ data: TResponse | null; error: ClientErrorData | null }> => {
    try {
      const response = await fetch(url, config)
      if (!response.ok) {
        return await getErrorResponse(response)
      } else {
        if (response.status === 204) {
          return { data: null, error: null }
        } else {
          const data = await response.json()
          if (data.errorData && data.errorData !== 0) {
            return await parseError(data)
          } else {
            if ((data as ResponseBase).userProfileActionHint === ProfileActionHint.AccessTokenNearEOF) {
              session.refreshAccessToken()
            } else if ((data as ResponseBase).userProfileActionHint === ProfileActionHint.AccessTokenRefreshed) {
              session.accessToken = (data as ResponseBase).accessToken!
            }
            return { data: data as TResponse, error: null }
          }
        }
      }
    } catch (err: any) {
      return {
        data: null,
        error: {
          code: err.name,
          errClass: `fatal::${err}`,
          message: getErrorMessage(err.name),
        },
      }
    }
  }

  const getErrorMessage = (errorName: string): string => {
    if (errorName === 'TimeoutError') {
      return ''
    } else if (errorName === 'AbortError') {
      return ''
    } else if (errorName === 'TypeError') {
      return ''
    } else {
      return ''
    }
  }

  const getErrorResponse = async <TResponse>(
    response: Response,
  ): Promise<{ data: TResponse | null; error: ClientErrorData | null }> => {
    switch (response.status) {
      case 401:
        if (((await response.json()) as ResponseBase).userProfileActionHint === ProfileActionHint.RefreshUserProfile) {
          window.location.assign(loginPath)
        }
        return {
          data: null,
          error: {
            code: 'unknown',
            errClass: 'net',
            message: 'General network error, please check your proxy settings',
          },
        }
      case 409:
        window.location.assign(`/lockout?lockOutCode=${PublicSupportCode.MultipleSessionBlock}`)
        return {
          data: null,
          error: {
            code: SystemErrorCodes.LogonConflict,
            errClass: 'security',
            message: await response.text(),
          },
        }
      case 0:
      default:
        return {
          data: null,
          error: {
            code: response.status as unknown as string,
            errClass: 'net',
            message: `Unexpected error ${response.status}, server failed to handle your request`,
          },
        }
    }
  }
  return { request }
}
