import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useSideMenuApi } from '@/api/services/side-menu'
import { useToastStore } from '@/shared/stores/toast'
import type {
  MBoxDetailsRequest,
  ClientErrorData,
  Carrier,
  ExternalKey,
  OrderLine,
  OrderLineExtended,
  OrderLineDetailsRequest,
  MboxDetailsSectionResponse,
} from '@/api/models/types'
import {
  DefaultDetailsSubSection,
  DefaultDetailsSubSectionGeneral,
  DefaultOrderLine,
  DefaultOrderLineExtended,
} from '@/api/models/defaults'
import { ERROR } from '@/constants'
import { AccordionFocus } from '@/shared/interfaces/accordionFocus'
import { MBoxReferenceType, ClientSettings } from '@/api/models/definitions'
import type { MotionTrack } from '@/shared/interfaces/item'
import { useUserStore } from '@/modules/user/stores/user'

export const useSelected = defineStore('selected', () => {
  const nullKey = '00000000-0000-0000-0000-000000000000'
  const nullNeedsLokup = '10000000-0000-0000-0000-000000000001'

  const lastSelectedId = ref<number | null>(null)
  const selection = ref<{ [id: number]: number }>([]) // value = index of item in motion raw list
  const hasFocus = ref<boolean>(false)
  const externalKey = ref<ExternalKey>({ key: nullKey })
  const orderLine = ref<OrderLine>(DefaultOrderLine)
  const orderLineDetails = ref<OrderLineExtended>(DefaultOrderLineExtended)

  const focusInfoTab = ref<AccordionFocus>(new AccordionFocus(true))
  const focusedActionType = ref<AccordionFocus>(new AccordionFocus(false))
  const focusedProductType = ref<AccordionFocus>(new AccordionFocus(false))

  const detailsSection = ref<MboxDetailsSectionResponse | null>()
  const orderLines = ref<OrderLine[]>([])

  const infoCache: { [id: number]: MboxDetailsSectionResponse } = {}

  const api = useSideMenuApi()
  const toastStore = useToastStore()
  const user = useUserStore()

  const syncDetails = async (): Promise<void> => {
    if (!lastSelectedId.value) return

    focusInfoTab.value.showIndicator = true

    const cacheKey = lastSelectedId.value
    if (focusInfoTab.value.isExpanded) {
      if (infoCache[cacheKey]) {
        detailsSection.value = infoCache[cacheKey]
        focusInfoTab.value.showIndicator = false
      }
    } else {
      focusInfoTab.value.showIndicator = false
    }

    const payload: MBoxDetailsRequest = {
      objectId: lastSelectedId.value as number,
      focusedGeneralTab: focusInfoTab.value.isExpanded ? focusInfoTab.value.focusedTab + 1 : null!,
      focusedActionType: focusedActionType.value.isExpanded ? focusedActionType.value.focusedTab : null!,
      externalKey: externalKey?.value || null!,
      isCarrierAccount: user.checkClientSetting(ClientSettings.isCarrierClientAccount),
    }

    let extKeyLookupNeeded = true

    // Load "main" accordion sync
    if (
      focusInfoTab.value.isExpanded &&
      focusInfoTab.value.contentSignature != focusInfoTab.value.calcContentSignature(lastSelectedId.value)
    ) {
      let res: { data: MboxDetailsSectionResponse | null; error: ClientErrorData | null }
      switch (payload.focusedGeneralTab) {
        case 2:
          res = await api.fetchSideMenuInformationShipment(payload)
          break
        case 3:
          res = await api.fetchSideMenuInformationRecipient(payload)
          extKeyLookupNeeded = false
          break
        default:
          res = await api.fetchSideMenuInformationGeneral(payload)
          extKeyLookupNeeded = false
      }
      if (lastSelectedId.value !== payload.objectId) {
        // focused object has changed since request was fired, halt.
        return
      }

      if (res.error) {
        toastStore.showToast(ERROR, res.error.message)
        infoCache[cacheKey] = {
          general: infoCache[cacheKey]?.general || DefaultDetailsSubSectionGeneral,
          shipment: infoCache[cacheKey]?.shipment || DefaultDetailsSubSection,
          recipient: infoCache[cacheKey]?.recipient || DefaultDetailsSubSection,
        }
        detailsSection.value = infoCache[cacheKey]
        focusInfoTab.value.setContentSignature(lastSelectedId.value!)
      } else {
        infoCache[cacheKey] = {
          general: res.data?.general || infoCache[cacheKey]?.general,
          shipment: res.data?.shipment || infoCache[cacheKey]?.shipment,
          recipient: res.data?.recipient || infoCache[cacheKey]?.recipient,
        }
        detailsSection.value = infoCache[cacheKey]

        externalKey.value = payload.externalKey = res.data!.externalKey || externalKey.value

        if (payload.focusedGeneralTab === 1) {
          if (res.data!.general?.shipmentGroupCarrierId) await resolveGroupParentTrackingUrl()
          else await resolveTrackingUrl()
        }
        focusInfoTab.value.setContentSignature(lastSelectedId.value!)
      }
      focusInfoTab.value.showIndicator = false
    }

    if (extKeyLookupNeeded) {
      externalKey.value = payload.externalKey = { key: nullNeedsLokup }
    }

    // Load all other accordions async

    if (
      focusedProductType.value.isExpanded &&
      focusedProductType.value.contentSignature != focusedProductType.value.calcContentSignature(lastSelectedId.value)
    ) {
      if (externalKey.value.key === nullKey) {
        orderLines.value = []
        focusedProductType.value.setContentSignature(lastSelectedId.value!)
      } else {
        focusedProductType.value.showIndicator = true
        api.fetchSideMenuProducts(payload).then((res) => {
          if (lastSelectedId.value !== payload.objectId) {
            return
          }
          focusedProductType.value.showIndicator = false
          if (res.error) {
            toastStore.showToast(ERROR, res.error.message)
            return
          }
          focusedProductType.value.setContentSignature(lastSelectedId.value!)
          if (!res.data || !res.data.lines) {
            orderLines.value = []
          } else {
            externalKey.value = res.data!.externalKey || { key: nullKey }
            orderLines.value = res.data.lines!
          }
        })
      }
    }
  }

  const resetExternalKey = () => {
    externalKey.value = { key: nullKey }
    orderLine.value = DefaultOrderLine
    orderLineDetails.value = DefaultOrderLineExtended
  }

  const resetAll = () => {
    lastSelectedId.value = null
    externalKey.value = { key: nullKey }
    orderLine.value = DefaultOrderLine
    orderLineDetails.value = DefaultOrderLineExtended

    focusInfoTab.value.setContentSignature(-1)
    focusedActionType.value.setContentSignature(-1)
    focusedProductType.value.setContentSignature(-1)

    detailsSection.value = null
    orderLines.value = []
    selection.value = []

    for (const obj in infoCache) {
      delete infoCache[obj]
    }
  }

  const resolveTrackingUrl = async (): Promise<void> => {
    if (!lastSelectedId.value || !detailsSection.value?.general) return
    if (detailsSection.value?.general.shipmentCarrierTrackerLink) return
    const res = await api.fetchTrackingUrl(lastSelectedId.value.toString())
    if (res.error) return
    detailsSection.value.general.shipmentCarrierTrackerLink = res.data?.externalUrl
  }

  const resolveGroupParentTrackingUrl = async (): Promise<void> => {
    if (!lastSelectedId.value || !detailsSection.value?.general?.shipmentGroupObjectId) return
    if (detailsSection.value?.general.shipmentCarrierTrackerLink) return
    const res = await api.fetchTrackingUrl(detailsSection.value?.general.shipmentGroupObjectId!.toString())
    if (res.error) return
    detailsSection.value.general.shipmentCarrierTrackerLink = res.data?.externalUrl
  }

  const getTrackingUrl = () => detailsSection.value?.general?.shipmentCarrierTrackerLink

  const getCarrier = (): Carrier | null => {
    if (!lastSelectedId.value || !detailsSection.value?.general) return null
    let carrier
    if (detailsSection.value?.general.shipmentGroupCarrierId)
      carrier = user.carriers.find((c) => c.id === detailsSection.value?.general?.shipmentGroupCarrierId)
    else carrier = user.carriers.find((c) => c.id === detailsSection.value?.general?.carrierId)
    if (!carrier) return null
    return carrier
  }

  const syncOrderLineDetails = async (): Promise<void> => {
    if (orderLine.value.id === DefaultOrderLine.id) return
    if (
      externalKey.value.objectType &&
      externalKey.value.objectType != MBoxReferenceType.WarehouseOrder &&
      externalKey.value.objectType != MBoxReferenceType.Shipment
    ) {
      toastStore.showToast(ERROR, `Line ref type ${externalKey.value.objectType} not yet supported`)
      return
    }
    const payload: OrderLineDetailsRequest = {
      objectId: lastSelectedId.value!,
      fromClientKey: externalKey.value.fromClient!,
      shipmentLineId: orderLine.value.id,
      objectType: orderLine.value.lineType,
    }
    const res = await api.fetchSideOrderLineDetails(payload)
    if (res.error) {
      orderLineDetails.value = DefaultOrderLineExtended
      toastStore.showToast(ERROR, res.error.message)
      return
    }
    orderLineDetails.value = res.data?.orderLine!
  }

  const expandSelection = (prev: number, next: MotionTrack) => {
    if (!next) return
    if (selection.value[next.id]) {
      // i.e. deselecting via shift+up/down
      if (selection.value[prev]) {
        delete selection.value[prev]
      }
    } else {
      selection.value[next.id] = next.index
    }
    lastSelectedId.value = next.id
  }

  const singleSelect = (selectedMBox: MotionTrack, ctrlKeyPressed: boolean = false) => {
    if (ctrlKeyPressed && selection.value[selectedMBox.id] !== undefined) {
      delete selection.value[selectedMBox.id]
      lastSelectedId.value = null
    } else {
      if (!ctrlKeyPressed) {
        selection.value = {}
      }
      selection.value[selectedMBox.id] = selectedMBox.index
      lastSelectedId.value = selectedMBox.id
    }
  }

  const bulkSelect = (ids: MotionTrack[], selectItemId: number | null = null) => {
    ids.forEach((mt) => (selection.value[mt.id] = mt.index))
    lastSelectedId.value = selectItemId
  }

  return {
    selection,
    hasFocus,
    lastSelectedId,
    singleSelect,
    externalKey,
    resetExternalKey,
    resetAll,
    focusInfoTab,
    focusedActionType,
    focusedProductType,
    detailsSection,
    syncDetails,
    getCarrier,
    getTrackingUrl,
    orderLines,
    orderLine,
    syncOrderLineDetails,
    orderLineDetails,
    expandSelection,
    bulkSelect,
  }
})
