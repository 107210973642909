import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useToastStore } from './toast'
import { useSessionApi } from '@/api/services/session'
import { ERROR } from '@/constants'

export const useSessionStore = defineStore('session', () => {
  const api = useSessionApi()
  const toastStore = useToastStore()
  const accessToken = ref<string>('')

  const checkForSessionResume = async (): Promise<boolean> => {
    const { data, error } = await api.getResume()
    if (error) {
      toastStore.showToast(ERROR, error.message)
      return false
    }
    if (data) {
      accessToken.value = data.accessToken!
      return true
    }
    return false
  }

  const refreshAccessToken = async (): Promise<boolean> => {
    const { data, error } = await api.getAccessToken()
    if (error) {
      toastStore.showToast(ERROR, error.message)
      return false
    }
    if (data) {
      accessToken.value = data.accessToken!
      return true
    }
    return false
  }

  return { accessToken, checkForSessionResume, refreshAccessToken }
})
