import {
  ClientSettings,
  StatusEventCategory,
  NotificationCategory,
  Permission,
  UserCategory,
} from '@/api/models/definitions'
import type { EventItem, Item } from '@/shared/interfaces/item'
import {
  mdiCog,
  mdiTruck,
  mdiHistory,
  mdiMonitor,
  mdiListBox,
  mdiSecurity,
  mdiTagCheck,
  mdiCartCheck,
  mdiCheckBold,
  mdiChartGantt,
  mdiAccountBox,
  mdiMessageText,
  mdiCartOutline,
  mdiViewDashboard,
  mdiPackageVariant,
  mdiTextBoxOutline,
  mdiNumeric1BoxOutline,
  mdiNumeric2BoxOutline,
  mdiNumeric3BoxOutline,
  mdiNumeric4BoxOutline,
  mdiNumeric5BoxOutline,
  mdiNumeric6BoxOutline,
  mdiNumeric7BoxOutline,
  mdiAccountGroup,
} from '@mdi/js'
import { useI18n } from 'vue-i18n'
export interface NavItem {
  title: string
  path: string
  icon: string
  clientConfigRequired?: string
  permissionRequired?: Permission
}

export const useLocaleOption = () => {
  const { t } = useI18n()

  const lanes: Item[] = [
    {
      id: 1,
      label: t('glossary.shipment', 2),
      icon: mdiNumeric1BoxOutline,
    },
    {
      id: 2,
      label: t('glossary.pickup'),
      icon: mdiNumeric2BoxOutline,
    },
    {
      id: 3,
      label: t('glossary.terminalIn'),
      icon: mdiNumeric3BoxOutline,
    },
    {
      id: 4,
      label: t('glossary.inTransit'),
      icon: mdiNumeric4BoxOutline,
    },
    {
      id: 5,
      label: t('glossary.terminalOut'),
      icon: mdiNumeric5BoxOutline,
    },
    {
      id: 6,
      label: t('glossary.arrived'),
      icon: mdiNumeric6BoxOutline,
    },
    {
      id: 7,
      label: t('glossary.delivered'),
      icon: mdiNumeric7BoxOutline,
    },
  ]

  const notifications: Item[] = [
    {
      id: NotificationCategory.All,
      label: t('common.all'),
      icon: mdiListBox,
    },
    {
      id: NotificationCategory.Carrier,
      label: t('glossary.carrierService'),
      icon: mdiTruck,
    },
    {
      id: NotificationCategory.Contact,
      label: t('glossary.contactService'),
      icon: mdiAccountBox,
    },
    {
      id: NotificationCategory.MyBoxes,
      label: t('glossary.myBox', 2),
      icon: mdiPackageVariant,
    },
    {
      id: NotificationCategory.System,
      label: t('glossary.systemService'),
      icon: mdiMonitor,
    },
    {
      id: NotificationCategory.User,
      label: t('glossary.userMessage', 2),
      icon: mdiMessageText,
    },
  ]

  const callOffSteps: Item[] = [
    {
      id: 1,
      label: t('glossary.customerOrder'),
      icon: mdiTextBoxOutline,
    },
    {
      id: 2,
      label: t('glossary.recipient'),
      icon: mdiAccountBox,
    },
    {
      id: 3,
      label: t('glossary.product', 2),
      icon: mdiCartOutline,
    },
    {
      id: 4,
      label: t('glossary.confirmation'),
      icon: mdiCheckBold,
    },
  ]

  const status: Item[] = [
    {
      id: StatusEventCategory.All,
      label: t('common.all'),
      icon: mdiListBox,
    },
    {
      id: StatusEventCategory.Status,
      label: t('glossary.motionView'),
      icon: mdiChartGantt,
    },
    {
      id: StatusEventCategory.Carrier,
      label: t('glossary.carrier'),
      icon: mdiTruck,
    },
    {
      id: StatusEventCategory.MyBoxes,
      label: t('glossary.myBox', 2),
      icon: mdiPackageVariant,
    },
  ]

  const action: Item[] = [
    {
      id: 1,
      label: t('glossary.active'),
      icon: mdiTagCheck,
    },
    {
      id: 2,
      label: t('glossary.history'),
      icon: mdiHistory,
    },
  ]

  const mainNavigation: NavItem[] = [
    {
      title: t('glossary.myDashboard'),
      path: '/',
      icon: mdiViewDashboard,
    },
    {
      title: t('glossary.motionView'),
      path: '/motion-view',
      icon: mdiChartGantt,
    },
    {
      title: t('glossary.productCallOff'),
      path: '/product-call-off',
      icon: mdiCartCheck,
      clientConfigRequired: ClientSettings.callOffViewEnabled,
    },
  ]

  const systemNavigation: NavItem[] = [
    {
      title: t('glossary.userManagement'),
      path: '/user-management',
      icon: mdiAccountGroup,
      permissionRequired: Permission.EnableUserManagement,
    },
    {
      title: t('glossary.setting', 2),
      path: '/settings',
      icon: mdiCog,
      permissionRequired: Permission.EnableClientSettingsMenu,
    },
    {
      title: t('glossary.administrator'),
      path: '/admin',
      icon: mdiSecurity,
      permissionRequired: Permission.EnableAdminMenu,
    },
  ]

  const userCategories: { [key: number]: string } = {
    [UserCategory.Agent]: t('glossary.agent', 2),
    [UserCategory.User]: t('glossary.user', 2),
    [UserCategory.SuperUser]: t('glossary.superUser', 2),
    [UserCategory.Template]: t('glossary.template', 2),
    [UserCategory.ServiceUser]: t('glossary.serviceUser', 2),
    [UserCategory.SystemUser]: t('glossary.systemUser', 2),
    [UserCategory.Admin]: t('glossary.admin', 2),
  }

  const hideOrderReasons: EventItem[] = [
    {
      eventCode: 'OB_HIDE_ORDER_R01',
      label: 'Order cancelled, customer changed their mind',
    },
    {
      eventCode: 'OB_HIDE_ORDER_R02',
      label: 'Order cancelled, stopped by the sender',
    },
  ]

  const hideShipmentReasons: EventItem[] = [
    {
      eventCode: 'OB_HIDE_SHIPMENT_R01',
      label: 'Shipment label not used',
    },
    {
      eventCode: 'OB_HIDE_SHIPMENT_R02',
      label: 'Shipment lost by carrier, replacement has been done',
    },
    {
      eventCode: 'OB_HIDE_SHIPMENT_R03',
      label: 'Shipment lost by carrier, no replacement shall be shipped',
    },
  ]

  const cancelReasons = [
    {
      serviceCode: 'OB_SERVICE_REQUEST_R03',
      label: 'Replacement has been done',
    },
    {
      serviceCode: 'OB_SERVICE_REQUEST_R04',
      label: 'No replacement shall be shipped',
    },
    {
      serviceCode: 'OB_SERVICE_REQUEST_R01',
      label: 'Customer change their mind',
    },
    {
      serviceCode: 'OB_SERVICE_REQUEST_R02',
      label: 'Fraud suspicion stopped delivery',
    },
  ]

  return {
    lanes,
    notifications,
    callOffSteps,
    status,
    action,
    cancelReasons,
    mainNavigation,
    systemNavigation,
    userCategories,
    hideOrderReasons,
    hideShipmentReasons,
  }
}
