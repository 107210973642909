<script setup lang="ts">
import { ref } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import BadgeButton from '@/shared/components/BadgeButton.vue'

interface Props {
  title: string
  icon: string
}

interface Emits {
  (e: 'click'): void
}

defineProps<Props>()
defineEmits<Emits>()
defineOptions({ inheritAttrs: false })
const model = defineModel<boolean>()
const isActive = ref<boolean>(false)
</script>

<template>
  <div class="relative transition-opacity ease-in-out duration-200" v-on-click-outside="() => (isActive = false)">
    <BadgeButton v-model="isActive" :title="title" :icon="icon" :badge="model" @click="$emit('click')" />
    <div
      class="z-40 absolute right-0 mt-0.5 origin-top-right border border-gray-300 bg-white transition-all transform ease-in-out duration-200 focus:outline-none overflow-hidden text-nowrap shadow-md"
      :class="isActive ? 'scale-100 opacity-100' : 'scale-75 opacity-0 collapse'"
      v-bind="$attrs"
    >
      <slot></slot>
    </div>
  </div>
</template>
