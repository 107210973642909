import { useApi } from '..'
import type { ClientErrorData, MaintenanceSchedule } from '../models/types'

export const useMaintenanceApi = () => {
  const api = useApi()
  const systemUrl = import.meta.env.VITE_SYSTEM_BASE_URL

  const getMaintenance = async (): Promise<{ data: MaintenanceSchedule | null; error: ClientErrorData | null }> =>
    await api.request.get<MaintenanceSchedule>(systemUrl, 'maintenance')

  return { getMaintenance }
}
