<script setup lang="ts">
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { mdiBell, mdiBellCircle, mdiClockAlertOutline } from '@mdi/js'
import { useMaintenanceStore } from '@/shared/stores/maintenance'
import BadgeMenu from '@/shared/components/BadgeMenu.vue'
import NotificationMenuItem from './NotificationMenuItem.vue'
import SvgIcon from '@/shared/components/SvgIcon.vue'

const maintenanceStore = useMaintenanceStore()
const isUnread = ref<boolean>(false)
const { isReminder, timeRemaining } = storeToRefs(maintenanceStore)

watch(isReminder, () => {
  isUnread.value = isReminder.value
})

maintenanceStore.getMaintenance()
</script>

<template>
  <BadgeMenu v-model="isUnread" :title="$t('glossary.notification', 2)" :icon="mdiBell" class="w-80 truncate pb-2">
    <div class="text-sm border-b border-gray-300 p-2">{{ $t('glossary.notification', 2) }}</div>
    <div v-if="isReminder" class="px-2">
      <div class="text-xs text-gray-700 py-2">{{ $t('glossary.systemAlert', 2) }}</div>
      <NotificationMenuItem
        v-model="isUnread"
        :title="$t('glossary.serviceMaintenance')"
        :text="timeRemaining"
        :icon="mdiClockAlertOutline"
      />
    </div>
    <div v-else class="text-center">
      <div class="flex justify-center items-center p-2">
        <SvgIcon :path="mdiBellCircle" class="fill-gray-300 h-16 w-16" />
      </div>
      <div class="font-light text-sm text-gray-700">{{ $t('message.noNotification', 2) }}</div>
    </div>
  </BadgeMenu>
</template>
