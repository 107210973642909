<script setup lang="ts">
import { ref } from 'vue'
import { sv } from 'date-fns/locale'
import { RouterView } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useUserStore } from './modules/user/stores/user'
import { useClientStore } from './modules/client/store/client'
import { useNavigationStore } from './modules/navbar/stores/navigation'
import { useSessionStore } from './shared/stores/session'
import { useThemeStore } from '@/shared/stores/theme'
import { setDefaultOptions } from 'date-fns'
import NavBar from '@/modules/navbar/components/NavBar.vue'
import FooterBar from '@/modules/footerbar/components/FooterBar.vue'
import HeaderBar from './modules/headerbar/components/HeaderBar.vue'
import ToastMessage from './shared/components/ToastMessage.vue'
import SplashLoader from './shared/components/SplashLoader.vue'
import VersionUpdateDialog from './modules/version/components/VersionUpdateDialog.vue'

const navigation = useNavigationStore()
const sessionStore = useSessionStore()
const userStore = useUserStore()
const clientStore = useClientStore()
const themeStore = useThemeStore()
const isLoading = ref<boolean>(true)
const { viewId, views } = storeToRefs(userStore)

const setAppSettings = () => {
  themeStore.setDefaultTheme(viewId.value, views.value)
  setDefaultOptions({ locale: sv })
}

const isValidUser = async () => {
  return (
    (await sessionStore.refreshAccessToken()) &&
    (await userStore.refreshUser()) &&
    (await userStore.refreshUserSettings(clientStore.clientKey))
  )
}

const initApp = async () => {
  if (await isValidUser()) {
    setAppSettings()
    isLoading.value = false
  } else {
    window.location.assign(`${import.meta.env.VITE_LOBBY_BASE_URL}${import.meta.env.VITE_LOBBY_LOGIN_PATH}`)
  }
}

initApp()
</script>

<template>
  <template v-if="isLoading">
    <SplashLoader />
  </template>
  <div class="transition-opacity ease-in duration-200" :class="isLoading ? 'opacity-0' : 'opacity-100'">
    <template v-if="!isLoading">
      <header>
        <HeaderBar />
      </header>
      <nav>
        <NavBar />
      </nav>
      <div class="transition-all ease-in-out duration-200" :class="navigation.isExpanded ? 'pl-64' : 'pl-12'">
        <RouterView />
      </div>
      <ToastMessage />
      <VersionUpdateDialog />
      <footer>
        <FooterBar />
      </footer>
    </template>
  </div>
</template>
